import {React, Redirect} from "../../../../common/web_common/components/eevi_react_exports";
import {apiV1, EeviStandardContainer} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {coalesce, dateAddDays, eeviId, startOfWeek} from "../../../../common/web_common/components/eevi_util";
import {EeviForm, EeviFormProperties, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {EeviDateRangePicker} from "../../../../common/web_common/components/eevi_date_picker";
import {EeviDropDown, EeviDropDownMenuItem} from "../../../../common/web_common/components/eevi_drop_down";
import {defaultTimeRangeDays} from "../lib/dashboard_defaults";
import {DashboardLookup} from "../components/organisation";
import {EeviHelpLink} from "../../../../common/web_common/components/eevi_help_link";
import {eeviGlobal} from "../../../../common/web_common/components/eevi_context";

const quicksight = require("amazon-quicksight-embedding-sdk");

interface HomeState extends EeviFormState {
    embeddedUrl?: string;
    organisationLevel?: string;
    startDate?: Date;
    endDate?: Date;
    dashboardLookups: DashboardLookup[];
    dashboardTitle?: string;
}

interface DashboardParameters {
    organisationLevel?: string;
    endDate?: Date;
    endDateIsoFormat?: string;
    endDatePreviousDay?: Date;
    startDate?: Date;
    startDateWeek?: Date;
    timeZoneOffsetMinutes?: number;
}


export class HomeForm extends EeviForm<EeviFormProperties, HomeState> {
    private readonly api: EeviApi<HomeState>;
    private dashboard: any;
    private readonly currentDashboardParameters: DashboardParameters = {}

    constructor(props: EeviFormProperties) {
        super(props, "Loading dashboard...", true);
        this.api = EeviApi.fromComponent(this);
        const endDate = new Date();
        const startDate = startOfWeek(dateAddDays(endDate, -defaultTimeRangeDays), true);
        this.state = {
            ...this.initialState(),
            loading: true,
            dashboardLookups: [],
            startDate: startDate,
            endDate: endDate
        };

    }

    protected onFormDidMount() {
        this.setState({loading: true, error: null});
        this.api.get(`${apiV1}/embedded-dashboard`,
            undefined, undefined, undefined, false);
    }


    createDashboard(embeddedUrl?: string) {
        embeddedUrl = coalesce(embeddedUrl, this.state.embeddedUrl);
        if (embeddedUrl && !this.dashboard) {
            const options = {
                url: embeddedUrl,
                container: this.container!.formContentDiv,
                width: "100%",
                height: "0",
                parameters: this.getDashboardParameters(),
                loadCallback: () => this.onDashboardLoaded(false),
                errorCallback: (e: any) => (e: any) => this.setState({
                    error: `Your Quicksight session has expired. ${e}`
                })
            };
            eeviGlobal.log('Creating embedded dashboard');
            this.dashboard = quicksight.embedDashboard(options);
            // this.dashboard.on("load", () => this.onDashboardLoaded(false));
            // this.dashboard.on("error", (e: any) =>
            //     this.setState({error: `Your Quicksight session has expired. ${e}`}));
            this.dashboard.on("SHOW_MODAL_EVENT", () => window.scrollTo({top: 0}));
            setTimeout(() => this.onDashboardLoaded(true), 15000);
        }
    }

    private getDashboardParameters(): DashboardParameters {
        const currentDate = new Date();
        const endDate = this.state.endDate || currentDate;
        const startDate = this.getDefaultStartDate(currentDate);
        const value = {
            organisationLevel: this.state.organisationLevel,
            endDate: endDate,
            endDateIsoFormat: endDate.toISOString(),
            endDatePreviousDay: dateAddDays(this.state.endDate || currentDate, -1),
            startDate: startDate,
            startDateWeek: startOfWeek(startDate, true),
            timeZoneOffsetMinutes: -currentDate.getTimezoneOffset()
        };
        Object.assign(this.currentDashboardParameters, value);
        return value;
    }

    private getDefaultStartDate(currentDate: Date) {
        return this.state.startDate ||
            startOfWeek(dateAddDays(this.state.endDate || currentDate, -defaultTimeRangeDays), true);
    }

    onDashboardLoaded(forced: boolean) {
        if (this.state.loading) {
            eeviGlobal.log(`Dashboard loaded (forced=${forced})`);
            this.setState({loading: false, title: this.state.dashboardTitle});
        }

    }


    protected formContentDivStyle(): React.CSSProperties | undefined {
        const scaleHeight = 725;
        let horizontalMargin = 0;
        let top: number | undefined;
        let bottom: number | undefined;
        const verticalMargin = 10;
        let headerSize = 75;
        if (this.container && this.container.headerDiv) {
            headerSize = this.container!.headerDiv.clientHeight;
        }
        const clientHeight = this.state.windowHeight - headerSize;
        const aspectRatio = this.state.windowWidth / clientHeight;
        // different for different dashboards 6x4==1.45 6x3==1.9, 6x3+title==1.6
        let idealRatio: number;
        if (clientHeight < scaleHeight) {
            idealRatio = 1.5;
        } else {
            idealRatio = 1.6;
        }
        eeviGlobal.log(
            `Aspect Ratio: ${idealRatio} vs ${aspectRatio} Width: ${this.state.windowWidth} Height: ${clientHeight}`);
        if (this.state.windowWidth > this.startScalingAtWidth &&
            aspectRatio > idealRatio) {
            const width = idealRatio * clientHeight;
            horizontalMargin = (this.state.windowWidth - width) / 2;
            eeviGlobal.log(`Changed Aspect Ratio: ${width / clientHeight}`);
        }
        if (this.container && this.container!.headerDiv) {
            bottom = verticalMargin;
            top = headerSize + verticalMargin;
        }
        return {
            position: "absolute",
            left: horizontalMargin,
            right: horizontalMargin,
            top: top,
            bottom: bottom
        };
    }

    protected renderMenus(): React.ReactNode | undefined {
        const scaleDown = (this.state.windowWidth <= this.startScalingAtWidth);
        let menuButtonClass = this.getMenuButtonClass(scaleDown);
        const minDate = new Date(2017, 0, 1);
        const maxDate = new Date();
        return <div className="d-flex justify-content-center">
            <EeviDateRangePicker
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(startDate, endDate) => this.changeDates(startDate, endDate)}
                menuButtonClass={menuButtonClass}
                minDate={minDate}
                maxDate={maxDate}
                scaleDown={scaleDown}
            />
            {this.state.dashboardLookups.length > 1 &&
            <EeviDropDown
                menuButtonContent={scaleDown ? "" : " More..."}
                menuButtonClass={menuButtonClass}
                fontIconClass="fas fa-chart-line"
                menuItems={this.state.dashboardLookups.map(dl => this.getDashboardChangeHandler(dl))}
            />
            }
            <EeviHelpLink content={scaleDown ? "" : " Help"} link="help"/>
        </div>;

    }


    private changeDates(startDate?: Date, endDate?: Date) {
        this.setState({startDate: startDate, endDate: endDate});
    }

    private getDashboardChangeHandler(dl: DashboardLookup): EeviDropDownMenuItem {
        // eevi, eevi.aveo are headings but not eevi.aveo.somewhere
        const isHeading = dl.organisationLevel.split('.').length < 3;
        const title = dl.title == 'Eevi' ? 'Eevi Customers' : dl.title;
        return {
            onClick: () => this.setState({
                organisationLevel: dl.organisationLevel,
                title: title,
            }),
            label: dl.title,
            key: eeviId(),
            isBold: isHeading,
            isIndented: !isHeading,
            addDividerBefore: dl.organisationLevel === 'eevi.no-group'
        };
    }

    renderDashboard() {
        if (this.containerIsLoaded()) {
            this.dashboard.iframe.height = this.container!.formContentDiv!.clientHeight;
            this.dashboard.iframe.className = "shadow";
            const parameters: DashboardParameters = this.currentDashboardParameters;
            if (parameters.organisationLevel != this.state.organisationLevel ||
                parameters.startDate != this.state.startDate ||
                parameters.endDate != this.state.endDate) {
                this.dashboard.setParameters(this.getDashboardParameters() as any);
            }
        } else {
            setTimeout(() => this.renderDashboard(), 200);
        }
    }

    private containerIsLoaded(): boolean {
        if (this.container && this.container!.formContentDiv) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Puts the standard header in place around the form, sets up error handling, user login.
     */
    render(): React.ReactNode {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect!} push/>;
        }
        const formContentDivStyle = this.formContentDivStyle();
        return <EeviStandardContainer
            ref={(instance) => this.container = instance}
            title={this.getTitle()}
            formState={this.state}
            formContentDivStyle={formContentDivStyle}
            hideTopMargin={this.hideTopMargin}
            menuLinks={this.menuLinks()}
            renderedMenus={this.allowRenderMenus() ? this.renderMenus() : undefined}
            login={(c) => this.login(c)}
            addLoginRegisterButton={false}>
            {this.renderForm()}
        </EeviStandardContainer>;
    }

    renderForm(): React.ReactNode {
        if (!this.dashboard) {
            this.createDashboard(this.state.embeddedUrl);
        } else {
            if (!this.state.loading) {
                this.renderDashboard();
            }
        }
        return <></>;

    }

}
