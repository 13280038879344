import AceEditor from "react-ace";
import {EeviFormFeedback} from "../components/eevi_form_feedback";
import {EeviApi} from "../components/eevi_api";
import {apiV1, MenuLink} from "../containers/eevi_std_container";
import {EeviConfirmButton} from "../components/eevi_confirm_button";
import {isNil} from "../components/eevi_util";
import {EeviForm, EeviFormProperties, EeviFormState} from "./eevi_form";
import {
    Button,
    Col,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    React,
    Row,
    UncontrolledTooltip
} from "../components/eevi_react_exports";
import {DiffContent, EeviDiffContent} from "../components/eevi_diff";

require('brace/theme/eclipse');
require('brace/mode/yaml');
require('brace/ext/language_tools');
require('brace/ext/searchbox');

interface YamlDoc {
    modified_config: string | undefined | null;
    src_config: string;
    diff?: DiffContent[];
}

interface SetupOrganisationsState extends EeviFormState {
    windowHeight: number;
    windowWidth: number;
    showWarning: boolean;
    document: YamlDoc;
}


export class EeviAdminOrganisationsForm extends EeviForm<EeviFormProperties, SetupOrganisationsState> {
    private readonly api: EeviApi<SetupOrganisationsState>;

    constructor(props: any) {
        super(props, "Organisations", true);
        this.api = EeviApi.fromComponent(this);
        this.state = {
            ...this.initialState(),
            loading: true,
            showWarning: false,
            document: {src_config: '', modified_config: null}
        };
    }


    onFormDidMount() {
        this.api.get(`${apiV1}/organisations`, "document");
    }


    private save() {
        this.api.connectWebSocket("document", undefined, true)
            .then((wsConnectionId) => {
                this.state.document.diff = undefined;
                const data = {wsConnectionId: wsConnectionId, ...this.state.document};
                this.api.put(`${apiV1}/organisations`, data);
            });

    }
    private toggleEditCompare() {
        if (this.state.document.diff === undefined) {
            //    do diff compare
            this.api.post(
                `${apiV1}/organisations_compare`,
                this.state.document,
                "document",
                undefined,
                true
            );
        } else {
            this.setState({
                document: {
                    diff: undefined,
                    modified_config: this.state.document.modified_config,
                    src_config: this.state.document.src_config
                }
            });
        }
    }

    
    private cancel() {
        this.api.get(`${apiV1}/organisations`, "document");
    }

    private onChangeDoc(doc: string) {
        this.setState(prev => ({
            document: {
                modified_config: doc,
                src_config: prev.document.src_config,
                diff: undefined
            }
        }))
    }

    private getDoc(): string {
        return isNil(this.state.document.modified_config) ?
            this.state.document.src_config :
            this.state.document.modified_config!;
    }

    protected menuLinks(): MenuLink[] {
        return [
        ];
    }

    renderForm(): React.ReactNode {
        const modified_config = this.state.document.modified_config;
        const diff = this.state.document.diff;
        return <>
            {this.warningModal()}
            {
                diff == undefined ? <AceEditor
                    value={this.getDoc()}
                    onChange={(doc: string) => this.onChangeDoc(doc)}
                    mode="yaml"
                    theme="eclipse"
                    fontSize={14}
                    enableBasicAutocompletion={true}
                    enableLiveAutocompletion={true}
                    showGutter={true}
                    showPrintMargin={false}
                    setOptions={{showLineNumbers: true}}
                    editorProps={{$blockScrolling: Infinity}}
                    width="100%"
                    className="border"
                    height={`${this.state.windowHeight - 200}px`}
                    name="organisation_edit"/> :
                    <EeviDiffContent
                        items={diff}
                        width="100%"
                        height={`${this.state.windowHeight - 200}px`}
                        className="table-responsive card"
                    />
            }
            <Row>
                <Col className="ml-5">
                    <EeviFormFeedback fieldName="modified_config" state={this.state}/>
                </Col>
                <Col className="text-right">
                    <Button
                        disabled={this.state.loading || isNil(modified_config) || isNil(diff)}
                        onClick={() => this.save()}
                        className="m-1 eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id="save_org">apply</Button>
                    <Button
                        disabled={this.state.loading || isNil(modified_config)}
                        onClick={() => this.toggleEditCompare()}
                        className="m-1 eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id="compare_edit">{this.state.document.diff ? "edit" : "review"}</Button>

                    <EeviConfirmButton
                        title="Discard any changes?"
                        message="This discard any edits and reload configuration data."
                        disabled={this.state.loading || isNil(modified_config)}
                        onClick={() => this.cancel()}
                        size="sm" color="outline-secondary"
                        className="m-1 eevi_edit_button"
                        id="cancel_org">cancel</EeviConfirmButton>
                    <Button
                        id="help"
                        disabled={this.state.loading}
                        onClick={() => this.setState({showWarning: true})}
                        size="sm" color="outline-secondary"
                        className="m-1 eevi_edit_button">
                        <i className="far fa-question-circle"/>
                    </Button>

                    <UncontrolledTooltip target="save_org" delay={400}>
                        update database
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="cancel_org" delay={400}>
                        discard changes
                    </UncontrolledTooltip>
                    <UncontrolledTooltip target="help" delay={400}>
                        I don't get it
                    </UncontrolledTooltip>
                </Col>
            </Row>
        </>;
    }

    private warningModal() {
        return <Modal
            centered={true}
            isOpen={this.state.showWarning}>
            <ModalHeader>Eevi System Administrators</ModalHeader>

            <ModalBody>
                <p className="mb-1">Use this form to configure corporate customers.</p>
                <em>Define an organisation by specifying:</em>
                <ul>
                    <li>
                        Sub-groups and access levels
                        <ul>
                            <li>use the <em>Permissions</em> form to give administrators access
                                to a particular access level.
                            </li>
                        </ul>
                    </li>
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => this.setState({showWarning: false})}
                    color="primary">ok</Button>
            </ModalFooter>
        </Modal>;
    }
}
