import {EeviColumnDefinition, EeviTable} from "../../../../common/web_common/components/eevi_table";
import {
    defaultValue, localDateTimeString, use
} from "../../../../common/web_common/components/eevi_transform";
import {EeviEventHandler} from "../../../../common/web_common/components/eevi_event_handler";
import {React, Table} from "../../../../common/web_common/components/eevi_react_exports";

export class MobileNetworkEvent {
    details: string = "";
    deviceId: string = "";
    mobileNetworkLostTimestamp: number = 0;
    isActive: boolean = false;
    downtimeSeconds: number = 0;
    deviceReportTimestamp?: number;
    deviceOnlineTimestamp?: number;
    deviceFoundTimestamp?: number;
}


/**
 * Adds some calculated columns to what comes across the wire
 */
// class ComputedMobileNetworkEvent extends MobileNetworkEvent {
//
//     get eventId(): string {
//         return `${this.deviceId}.${this.mobileNetworkLostTimestamp}`;
//     }
//
// }


const localDateTimeStringIfExists = (d: string | number | undefined) => d ? localDateTimeString(d) : '-';

/**
 * Note: dataKey must be a property of MobileNetworkEvents of you will get a compile error!!!
 */
const columnDefinitions: Array<EeviColumnDefinition<MobileNetworkEvent>> = [
    {
        columnHeaderTitle: "Date",
        dataKey: "mobileNetworkLostTimestamp",
        formatter: localDateTimeString
    },
    {
        columnHeaderTitle: "Resident",
        dataKey: "details",
        styles: [use('keep-lines'), use('text-justify')]
    },
    {
        columnHeaderTitle: "Downtime (seconds)",
        dataKey: "downtimeSeconds",
    },
    {
        columnHeaderTitle: "Found",
        dataKey: "deviceFoundTimestamp",
        formatter: localDateTimeStringIfExists,
        styles: [defaultValue('center')]
    },
    {
        columnHeaderTitle: "Online",
        dataKey: "deviceOnlineTimestamp",
        formatter: localDateTimeStringIfExists,
        styles: [defaultValue('center')]
    },
    {
        columnHeaderTitle: "Reported",
        dataKey: "deviceReportTimestamp",
        formatter: localDateTimeStringIfExists,
        styles: [defaultValue('center')]
    },

];

const primaryKey: keyof MobileNetworkEvent = "mobileNetworkLostTimestamp";

interface TableProps {
    networkEvents: Array<MobileNetworkEvent>;
    onSelect?: EeviEventHandler<MobileNetworkEvent>;
    status: string;
    totalTimeSeconds: number;
}

export function MobileNetworkEventsTable(props: TableProps) {
    if (props.networkEvents.length == 0) {
        return <p className="mx-auto p-5">No mobile outage events</p>;
    }
    // const compute = (i: MobileNetworkEvent) => new ComputedMobileNetworkEvent(i);
    const filter = props.status === 'ACTIVE' ? (n: MobileNetworkEvent) => n.isActive : (n: MobileNetworkEvent) => true;
    const data = props.networkEvents.filter(filter);
    const totalDowntime = data.reduce((total, next) => total + next.downtimeSeconds, 0);
    const activeDevices = 7;
    const totalDeviceTime = props.totalTimeSeconds * activeDevices;
    const percentUp = 100.0 - 100.0 * totalDowntime / totalDeviceTime;
    // TODO: css / standardize this.
    return <div className="p-3 mx-auto mt-3" style={{maxWidth: 1400}}>
        <Table striped bordered hover responsive>
            <thead>
            <tr>
                <th>Total Period (seconds)</th>
                <th>Active Devices</th>
                <th>Total Device Time</th>
                <th>Total Down Time (seconds)</th>
                <th>%</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{props.totalTimeSeconds}</td>
                <td>{activeDevices}</td>
                <td>{totalDeviceTime}</td>
                <td>{totalDowntime}</td>
                <td>{percentUp}</td>
            </tr>
            </tbody>
        </Table>
        <EeviTable
            columnDefinitions={columnDefinitions}
            primaryKey={primaryKey}
            data={data}
            onSelect={props.onSelect}/>
    </div>;
}