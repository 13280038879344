import {React} from "../../../../common/web_common/components/eevi_react_exports";
import {apiV1} from "../../../../common/web_common/containers/eevi_std_container";
import {DashboardLookup} from "../components/organisation";
import {DetailsForm, DetailsProps, DetailsState} from "./details_form_base";
import {InstallationSnapshot, InstallationSnapshotTable} from "../components/installation_snapshots_table";
import {isNil} from "../../../../common/web_common/components/eevi_util";


interface InstallationSnapshotState extends DetailsState {
    snapshots: InstallationSnapshot[];
}


/**
 * This form is usually called from a dashboard drill-down.
 */
export class InstallationSnapshotForm extends DetailsForm<InstallationSnapshotState> {

    constructor(props: DetailsProps) {
        super(props, "Installations", true);

        this.state = {
            ...this.initialState(),
            snapshots: []
        };

    }

    /**
     * Let base class know where our help is
     */
    protected getHelpUrl(): string {
        // const mode = this.getFormMode();
        let anchor = "#ddreports";
        // if (mode === 'OPEN') {
        //     anchor = "#ddnoresponse";
        // } else if (mode === 'RESPONSE') {
        //     anchor = "#ddalarmresponse";
        // } else if (mode === 'GOS') {
        //     anchor = "#ddalarmgos";
        // } else if (mode === 'ALL') {
        //     anchor = "#ddreports";
        // }
        return 'help' + anchor;
    }


    /**
     * Read any parameters from the url and kick off a call to the back end.
     * Note that the EeviAp instance is bound to the form so it can automatically update
     * the form state with the retrieved data.
     */
    protected onFormDidMount() {
        const params = this.getUrlParameters();
        let url =
            `${apiV1}/installation-snapshots?group=${params.group}` +
            `&endDate=${encodeURIComponent(params.endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(params.startDate.toISOString())}`;
        if (params.limit === 'LATEST') {
            url += '&latest=true'
        }
        this.api.get(url);
    }

    /**
     * Called when the user scrolls past a certain point if this.state.nextToken exists.
     * Note: Back end code that makes use of the paging capability of the Eevi Rest classes in common
     * returns nextToken with each block of data. We make another api call but rather than directly
     * updating the form state we call this.updateNextPage() to append (rather than replace) the data.
     */
    protected onNextPage(nextToken: string) {
        const url =
            `${apiV1}/installation-snapshots?group=${this.state.organisationLevel}` +
            `&endDate=${encodeURIComponent(this.state.endIsoTime!)}` +
            `&startDate=${encodeURIComponent(this.state.startIsoTime!)}` +
            `&nextToken=${nextToken}`;
        this.api.get(
            url, undefined, undefined, (nextPage) => this.updateNextPage(nextPage));
    }

    /**
     * Callback function from the api object because we want to append the set of events to the existing.
     */
    private updateNextPage(nextPage: Partial<InstallationSnapshotState>): Partial<InstallationSnapshotState> {
        // append new events to old
        nextPage.snapshots = [...this.state.snapshots, ...nextPage.snapshots || []];
        return nextPage;
    }

    /**
     * Event handler when the user chooses a different organisation / level from the filter menu.
     */
    protected onChangeOrganisation(dl: DashboardLookup) {
        this.changeOrganisation(dl);

        const redirect =
            `/groups/${dl.organisationLevel}/installation-snapshots` +
            `/${this.state.startIsoTime}/${this.state.endIsoTime}/${this.getFormMode()}`;
        // TODO: there's a weird issue with the new react router stuff
        // we are only getting the url change. That will suffice for now since we already had the code
        // to update this form.
        this.props.history.push(redirect);

        this.api.get(
            `${apiV1}/installation-snapshots` +
            `?group=${dl.organisationLevel}` +
            `&endDate=${encodeURIComponent(this.state.endIsoTime!)}` +
            `&startDate=${encodeURIComponent(this.state.startIsoTime!)}`
        );

    }

    /**
     * Event handler for when the user changes the start or end date.
     */
    protected onChangeDateRange(startDate: Date, endDate: Date) {
        const redirect =
            `/groups/${this.state.organisationLevel}/installation-snapshots` +
            `/${startDate.toISOString()}/${endDate.toISOString()}/${this.getFormMode()}`;
        // TODO: there's a weird issue with the new react router stuff
        // we are only getting the url change. That will suffice for now since we already had the code
        // to update this form.
        this.props.history.push(redirect);
        this.setState({loading: true, nextToken: undefined});
        this.api.get(
            `${apiV1}/installation-snapshots` +
            `?group=${this.state.organisationLevel}` +
            `&endDate=${encodeURIComponent(endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(startDate.toISOString())}`
        );

    }

    /**
     * Called by the base class render to display the content beneath the menus / navigation bar area.
     */
    renderForm(): React.ReactNode {
        return <InstallationSnapshotTable
            scrollDownForMore={!isNil(this.state.nextToken)}
            snapshots={this.state.snapshots}
            status={this.getFormMode()}/>;
    }

}

