import AceEditor from "react-ace";
import {EeviFormFeedback} from "../components/eevi_form_feedback";
import {EeviApi} from "../components/eevi_api";
import {apiV1, MenuLink} from "../containers/eevi_std_container";
import {EeviConfirmButton} from "../components/eevi_confirm_button";
import {isNil} from "../components/eevi_util";
import {EeviForm, EeviFormProperties, EeviFormState} from "./eevi_form";
import {
    Button,
    Col,
    Container, Input, InputGroup, InputGroupAddon,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    React,
    Row
} from "../components/eevi_react_exports";
import {EeviTooltip} from "../components/eevi_tooltip";

require('brace/theme/eclipse');
require('brace/mode/yaml');
require('brace/ext/language_tools');
require('brace/ext/searchbox');


interface YamlDoc {
    modified_config: string | undefined | null;
    src_config: string;
}

interface PermissionsState extends EeviFormState {
    windowHeight: number;
    windowWidth: number;
    showWarning: boolean;
    document: YamlDoc;
    searchText: string;
}

export class EeviAdminPermissionsForm extends EeviForm<EeviFormProperties, PermissionsState> {
    private readonly api: EeviApi<PermissionsState>;

    constructor(props: any) {
        super(props, "Account Permissions", true);
        this.api = EeviApi.fromComponent(this);
        this.state = {
            ...this.initialState(),
            loading: true,
            showWarning: false,
            document: {src_config: '', modified_config: null},
            searchText: ''
        };
    }


    protected onFormDidMount() {
        this.api.get(`${apiV1}/permissions`, "document");
    }

    private search() {
        const form = this;

        function doSearch() {
            form.setState({loading: true});
            form.api.get(`${apiV1}/permissions?search=${form.state.searchText}`, "document");
        }

        setTimeout(doSearch); // run on timer so we don't slow down onkeypress
    }

    private save() {
        this.setState({loading: true, searchText: ''});
        this.api.connectWebSocket("document", undefined, true)
            .then((wsConnectionId) => this.api.put(
                `${apiV1}/permissions`,
                {wsConnectionId: wsConnectionId, ...this.state.document},
                "document",
                undefined,
                true));
    }


    private cancel() {
        this.search();
    }

    private onChangeDoc(doc: string) {
        this.setState(prev => ({
            document: {
                modified_config: doc,
                src_config: prev.document.src_config
            }
        }))
    }

    private getDoc(): string {
        return isNil(this.state.document.modified_config) ?
            this.state.document.src_config :
            this.state.document.modified_config!;
    }

    protected menuLinks(): MenuLink[] {
        return [
            // {title: 'Account Permissions', link: '/permissions'},
            // {title: 'Org', link: '/organisations'},
        ];
    }

    renderForm(): React.ReactNode {
        return <Container fluid>
            {this.warningModal()}
            <div className="ml-5 mt-4 w-75">Enter yaml directly or search existing accounts.</div>
            <InputGroup className="ml-5 mb-3 w-75">
                <Input
                    disabled={this.state.loading || !isNil(this.state.document.modified_config)}
                    value={this.state.searchText}
                    onChange={(e) => this.setState({searchText: e.target.value})}
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') this.search()
                    }}
                />
                <InputGroupAddon addonType="append">
                    <Button
                        disabled={this.state.loading || !isNil(this.state.document.modified_config)}
                        className="btn-primary"
                        onClick={() => this.search()}
                    >search</Button>
                </InputGroupAddon>
            </InputGroup>
            <AceEditor
                value={this.getDoc()}
                onChange={(doc: string) => this.onChangeDoc(doc)}
                mode="yaml"
                theme="eclipse"
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                showGutter={true}
                showPrintMargin={false}
                setOptions={{showLineNumbers: true}}
                editorProps={{$blockScrolling: Infinity}}
                width="100%"
                className="border"
                height={`${this.state.windowHeight - 250}px`}
                name="organisation_edit"/>
            <Row>
                <Col className="ml-5">
                    <EeviFormFeedback fieldName="modified_config" state={this.state}/>
                </Col>
                <Col className="text-right">
                    <Button
                        disabled={this.state.loading || isNil(this.state.document.modified_config)}
                        onClick={() => this.save()}
                        className="m-1 eevi_edit_button"
                        size="sm"
                        color="outline-secondary"
                        id="save_org">apply</Button>
                    <EeviConfirmButton
                        title="Discard any changes?"
                        message="This discard any edits and reload configuration data."
                        disabled={this.state.loading || isNil(this.state.document.modified_config)}
                        onClick={() => this.cancel()}
                        size="sm" color="outline-secondary"
                        className="m-1 eevi_edit_button"
                        id="cancel_org">cancel</EeviConfirmButton>
                    <Button
                        id="help"
                        disabled={this.state.loading}
                        onClick={() => this.setState({showWarning: true})}
                        size="sm" color="outline-secondary"
                        className="m-1 eevi_edit_button">
                        <i className="far fa-question-circle"/>
                    </Button>

                    <EeviTooltip target="save_org">
                        Update database
                    </EeviTooltip>
                    <EeviTooltip target="cancel_org">
                        Discard changes
                    </EeviTooltip>
                    <EeviTooltip target="help">
                        I don't get it
                    </EeviTooltip>
                </Col>
            </Row>
        </Container>;
    }

    private warningModal() {
        return <Modal
            centered={true}
            isOpen={this.state.showWarning}>
            <ModalHeader>Eevi System Administrators</ModalHeader>

            <ModalBody>
                <p className="mb-1">Use this form to configure administrator login accounts and permissions.
                    Where an optional field is not specified any existing value remains unchanged.
                </p>
                <em>For each account specify:</em>
                <ul>
                    <li>
                        email - mandatory
                    </li>
                    <li>
                        access_levels - optional list of access levels (see Organisations Form)
                    </li>
                    <li>
                        password - optional password to allow the account to log into the system.
                    </li>
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => this.setState({showWarning: false})}
                    color="primary">ok</Button>
            </ModalFooter>
        </Modal>;
    }
}
