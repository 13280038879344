import {React, RouteComponentProps} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {
    dateAddDays,
    eeviId,
    endOfDay, endOfMinute, isEndOfDay,
    startOfDay, treatUtcDateAsLocal
} from "../../../../common/web_common/components/eevi_util";
import {EeviForm, EeviFormProperties, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {EeviDateRangePicker} from "../../../../common/web_common/components/eevi_date_picker";
import {EeviDropDown, EeviDropDownMenuItem} from "../../../../common/web_common/components/eevi_drop_down";
import {DashboardLookup} from "../components/organisation";
import {EeviHelpLink} from "../../../../common/web_common/components/eevi_help_link";


export interface DetailsState extends EeviFormState {
    startIsoTime?: string;
    endIsoTime?: string;
    dashboardLookups: DashboardLookup[];    // org level / name
    organisationLevel: string;
}

/**
 * Url parameters that can be passed to this form
 */
interface DetailsParams {
    groupLevel?: string;
    date?: string;
    intervalType?: string; // WK, DD or end date
    mode?: string; // ALL, OPEN, RESPONSE, GOS
}

/**
 * Common parameters that our derived classes will need to add to their various back end calls
 */
interface ParamsForBackEndCall {
    group: string; // eevi or the village, etc
    endDate: Date;
    startDate: Date;
    mode: string;  // ALL, OPEN, RESPONSE, GOS
    limit?: string // LATEST
}

/**
 * Pass the router history object as well as the form's properties so we can modify the url
 * in the browser address bar to match whatever filter properties have been chosen by the user.
 * This is so they can copy the url into an email or whatever.
 */
export type DetailsProps = EeviFormProperties & RouteComponentProps<DetailsParams>;

/**
 * Forms derived from this class are usually called from a dashboard drill-down.
 */
export abstract class DetailsForm<TState extends DetailsState> extends EeviForm<DetailsProps, TState> {
    protected readonly api: EeviApi<TState>;

    protected constructor(props: DetailsProps, readonly title: string, readonly hideTopMargin = false) {
        super(props, title, hideTopMargin);
        this.api = EeviApi.fromComponent(this);
    }


    protected initialState(): DetailsState {
        return {
            ...super.initialState(),
            loading: true,
            dashboardLookups: [],
            organisationLevel: this.props.match.params.groupLevel || 'eevi',
        };
    }

    protected getFormMode(): string {
        return (this.props.match.params.mode || 'ALL').toUpperCase();
    }

    protected getUrlParameters(): ParamsForBackEndCall {
        const group = this.props.match.params.groupLevel || 'eevi';
        const isoDate = this.props.match.params.date || new Date().toISOString();
        const intervalType = (this.props.match.params.intervalType || 'WK').toUpperCase();
        const mode = this.getFormMode();
        const dateParam = new Date(isoDate);
        const dateParamLocal = treatUtcDateAsLocal(dateParam);
        if (intervalType == 'DD') {
            // make the date picker show the same start date and end date if we are just running
            // for the full current day (ie 24h KPI menus)
            const startDate = isEndOfDay(dateParam) ?
                startOfDay(dateParam, true) : dateAddDays(dateParam, -1);

            return {
                group: group,
                mode: mode,
                endDate: dateParam,
                startDate: startDate
            };
        } else if (intervalType == 'WK') {
            // ignore the iso date and treat it like a local cos of quicksight-isms
            const startDate = startOfDay(dateParam, true);
            return {
                group: group,
                mode: mode,
                endDate: endOfDay(dateAddDays(startDate, 6)),
                startDate: startDate
            };
        } else if (intervalType == "LATEST") {
            return {
                group: group,
                mode: mode,
                endDate: endOfMinute(dateParamLocal),  // qs aggregates to minute at best
                startDate: dateParamLocal,
                limit: "LATEST"
            };

        } else {
            return {
                group: group,
                mode: mode,
                endDate: treatUtcDateAsLocal(new Date(intervalType)),
                startDate: dateParamLocal
            };
        }
    }


    protected changeOrganisation(dl: DashboardLookup) {
        const title = dl.title == 'Eevi' ? 'Eevi Customers' : dl.title;
        this.setState({
            organisationLevel: dl.organisationLevel,
            title: title,
            loading: true,
            nextToken: undefined
        });
    }

    /**
     * Url to the help doco for this form
     */
    protected abstract getHelpUrl(): string;

    /**
     * Event handler when the user chooses a different organisation / level from the filter menu.
     */
    protected abstract onChangeOrganisation(dl: DashboardLookup): void;

    /**
     * Event handler for when the user changes the start or end date.
     */
    protected abstract onChangeDateRange(startDate: Date, endDate: Date): void;

    /**
     * Called once for each organisation / level in the filter drop down.
     */
    private getDashboardChangeHandler(dl: DashboardLookup): EeviDropDownMenuItem {
        // eevi, eevi.aveo are headings but not eevi.aveo.somewhere
        const isHeading = dl.organisationLevel.split('.').length < 3;
        return {
            onClick: () => this.onChangeOrganisation(dl),
            label: dl.title,
            key: eeviId(),
            isBold: isHeading,
            isIndented: !isHeading
        };
    }

    /**
     * Called by the base class to render any custom menus in the navigation bar at the top of the form.
     */
    protected renderMenus(): React.ReactNode | undefined {
        if (!this.state.startIsoTime || !this.state.endIsoTime) {
            return undefined;
        }
        // TODO Make this EeviForm functionality that matches the Bootstrap grid classes.
        const scaleDown = (this.state.windowWidth <= this.startScalingAtWidth);
        let menuButtonClass = this.getMenuButtonClass(scaleDown);
        const minDate = new Date(2017, 1, 1);
        const maxDate = new Date();
        return <div className="d-flex justify-content-center">
            <EeviDateRangePicker
                startDate={new Date(this.state.startIsoTime!)}
                endDate={new Date(this.state.endIsoTime!)}
                onChange={(startDate, endDate) => this.onChangeDateRange(startDate, endDate)}
                menuButtonClass={menuButtonClass}
                minDate={minDate}
                maxDate={maxDate}
                scaleDown={scaleDown}
            />
            {this.state.dashboardLookups.length > 1 &&
            <EeviDropDown
                menuButtonContent={scaleDown ? "" : " Filter..."}
                menuButtonClass={menuButtonClass}
                fontIconClass="fal fa-filter"
                menuItems={this.state.dashboardLookups.map(dl => this.getDashboardChangeHandler(dl))}
            />
            }
            <EeviHelpLink content={scaleDown ? "" : " Help"} link={this.getHelpUrl()}/>
        </div>;

    }

}

