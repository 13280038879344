import {React} from "../../../../common/web_common/components/eevi_react_exports";

export function renderDevice(device: string) {
    let icon = "ml-3  fas fa-phone";
    if (device.includes('gateway')) {
        icon = "ml-3 fas fa-laptop-medical";
    }
    else if (device.includes('pendant')) {
        icon = "ml-3  fas fa-toggle-on";
    }
    else if (device.includes('watch')) {
        icon = "ml-3  fal fa-watch-fitness";
    }
    return <div>
        <i className={icon}>
            <i className="ml-1 eevi_text">{device}</i>
        </i>
    </div>;
}

export function renderAllDetails(details: string, devices: string[]) : React.ReactNode {
    return <>
        {details + '\n'}
        {devices.map(renderDevice)}
    </>;
}
