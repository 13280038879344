import {
    Button, Col,
    Container, Modal, ModalBody, ModalFooter, ModalHeader,
    React,
    Row,
    Table,
    withRouter
} from "../../../../common/web_common/components/eevi_react_exports";
import {apiV1} from "../../../../common/web_common/containers/eevi_std_container";
import {EeviForm, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {dynamicSubclass, strEnum} from "../../../../common/web_common/components/eevi_transform";
import {EeviApi} from "../../../../common/web_common/components/eevi_api";
import {eeviId} from "../../../../common/web_common/components/eevi_util";

export const EmailStatus = strEnum("sending", "sent", "failed")
export type EmailStatus = Omit<keyof typeof EmailStatus, "[Symbol.iterator]">;

export const EmailInvocationType = strEnum("ADHOC", "SCHEDULED")
export type EmailInvocationType = Omit<keyof typeof EmailInvocationType, "[Symbol.iterator]">;

interface EmailStatusPayload {
    email: string;
    sendingType: EmailInvocationType
    emailStatus: EmailStatus;
    lastStatusUpdateTime: string;
    reason?: string;
}

class EmailStatusCompute {
    protected get base(): EmailStatusPayload {
        return this as any;
    }

    get sending(): boolean {
        return this.base.emailStatus == "sending";
    }

    get lastUpdate(): Date {
        return new Date(this.base.lastStatusUpdateTime);
    }

    get status_msg(): string {
        if (this.sending) {
            return `Sending.`;
        } else if (this.base.emailStatus == "failed"){
            let reason = this.base.reason ? ` as ${this.base.reason?.toLowerCase()}.` : '.';
            return `${toCapitalised(this.base.sendingType.valueOf())} send failed` + reason;
        } else {
            let time_msg = this.lastUpdate ?
                    ` on ${this.lastUpdate.toLocaleDateString()} at ${this.lastUpdate.toLocaleTimeString()}.`
                : ".";

            return `${toCapitalised(this.base.sendingType.valueOf())} send completed successfully` + time_msg;
        }
    }

    get status_icon(): JSX.Element {
        if (this.sending) {
            return <div className={'eevi_small_loader'}/>;
        } else if (this.base.emailStatus == "failed"){
            return <div className={'error-icon'} style={{width:"auto", height:"3vh"}}/>;
        } else {
            return <div className={'success-icon'} style={{width:"auto", height:"3vh"}}/>;
        }
    }

    get id(): string {
        return this.base.email + "-" + this.base.sendingType
    }

    public localeCompare(r: EmailStatusRecord) {
        let different_date = this.base.lastStatusUpdateTime?.localeCompare(r.lastStatusUpdateTime)
        if (different_date){
            return -(different_date) //Older date come first
        } else {
            return this.base.email.localeCompare(r.email)
        }
    }
}

type EmailStatusRecord = EmailStatusCompute & EmailStatusPayload;


interface OnDemandEmailTicket {
    ticketId: string;
    adhocCode: string;
    recipients: string[];
    websocketId: string;
}

class MetricsReportState implements EeviFormState {
    error: any;
    loading?: boolean;
    nextToken?: string;
    redirect?: string;
    title?: string;
    windowHeight: number = 0;
    windowWidth: number = 0;

    records?: EmailStatusRecord[] = [];
    nextSendOn?: Date = new Date("2022-09-01T06:00:00");
    invalidEmailMsg?: string = "";

    showSendingProgress: boolean = false;
}

class MetricsReportForm extends EeviForm<any, MetricsReportState> {
    private readonly api: EeviApi<MetricsReportState>;
    private validRecipients: string[] = [];

    constructor(props: any) {
        super(props, "Monthly Metrics Report", false, false);
        this.api = EeviApi.fromComponent<MetricsReportState>(this);
        this.state = new MetricsReportState();
    }

    protected validateEmail(email: string, rejectedEmails: string[] | null = null): boolean{
        // Minimum requirement for a valid email, know if email is actually valid when send
        const validEmail = new RegExp('^.+@.+\\..+$');
        if (validEmail.test(email)) return true;

        // We are filtering out invalid emails before sending request -> display them to user
        if (rejectedEmails) {
            rejectedEmails.push(email);
        }
        return false;
    }

    private updateRecords(records: Partial<EmailStatusRecord>[]){
        const oldRecord = this.state.records!;
        const newRecord = new Array<EmailStatusRecord>();

        for (let record of records) {
            record = dynamicSubclass(record, EmailStatusCompute)
            let found = false;
            for (let existingRecord of oldRecord){
                if (existingRecord.id === record.id){
                    found = true;
                    Object.assign(existingRecord, record);
                    break;
                }
            }

            if (!found) newRecord.push(record as EmailStatusRecord);
        }

        return {
            records: [...oldRecord, ...newRecord].sort(
                (a,b) => a.localeCompare(b)
            )
        };
    }

    protected sendEmail(recipients: string[]) {
        let invalidEmails: string[] = [];
        this.validRecipients = recipients.filter((r) => {return this.validateEmail(r, invalidEmails)});
        let errorMsg = invalidEmails.length ? `Invalid email address(es): ${invalidEmails.join(", ")}` : "";

        this.setState({invalidEmailMsg: errorMsg})
        if (this.validRecipients.length == 0) return;

        let newEmailRecords: EmailStatusRecord[] = this.validRecipients.map(
                    (r) => {
                        return {
                            email: r,
                            sendingType: EmailInvocationType.ADHOC,
                            emailStatus: "sending",
                            lastStatusUpdateTime: new Date().toISOString(),
                        } as any as EmailStatusRecord
                    }
                )

        this.setState({...this.updateRecords(newEmailRecords), showSendingProgress: true});

        this.api.connectWebSocket<EmailStatusRecord>(
            undefined,
            (resp)=>{
                this.setState(this.updateRecords([resp]));
            }
        ).then(
            (wsConnectionId) => {
                let payload: OnDemandEmailTicket = {
                    ticketId: eeviId(),
                    adhocCode: "MONTHLY_METRICS_EMAIL",
                    recipients: newEmailRecords.map((r) => r.email),
                    websocketId: wsConnectionId
                }
                this.api.post<OnDemandEmailTicket>(
                    `${apiV1}/adhoc_request/send_analytics_email`,
                    payload,
                    undefined,
                    (data)=>{}
                )
            }
        )
    }


    protected onFormDidMount() {
        // Get the latest list of records
        this.api.get<MetricsReportState>(
            `${apiV1}/analytics_emails/status`,
            "records",
            (data) => {
                this.setState(
                    {
                        ...this.state,
                        ...this.updateRecords(data.records || [])
                    }
                );
            }
        )
    }


    protected renderForm(): React.ReactNode {
        return <Container className="MetricsReportPage" fluid>
            <ProgressModal
                records={this.state.records!.filter(
                    (r) => {
                        return this.validRecipients.includes(r.email) && r.sendingType == EmailInvocationType.ADHOC;
                    }
                )}
                visible={this.state.showSendingProgress}
                onClose={
                    () => this.setState({showSendingProgress: false})
                }
            />
            <Row style={{
                border: "solid",
                borderColor: "#faebcc",
                borderRadius: "10px 10px 10px 10px",
                height: "60vh",
                overflowY: "scroll"
            }}>
                <ReportStatus records={this.state.records!} nextSendOn={this.state.nextSendOn!}/>
            </Row>
            <Row style={{
                border: "solid",
                borderColor: "#faebcc",
                borderRadius: "10px 10px 10px 10px",
                height: "23vh",
                marginTop: "3vh"
            }}>
                <ManualSend sendEmail={(r: string[]) => this.sendEmail(r)} error={this.state.invalidEmailMsg!}/>
            </Row>
        </Container>
    }
}

function toCapitalised(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function ReportStatus(props: { records: EmailStatusRecord[], nextSendOn: Date }): JSX.Element {
    function resultRow(record: EmailStatusRecord): JSX.Element {
        return <tr key={record.id}>
            <td className={"col-md-1"}>{record.status_icon}</td>
            <td className={"col-md-3"}>{record.email}</td>
            <td className={"col-md-8"}>{record.status_msg}</td>
        </tr>
    }

    return <div style={{padding: "10px"}}>
        <p style={{fontSize: "1.33rem", marginBottom: "10px"}}>Send Details</p>
        <p style={{marginTop: "10px"}}>
            The next automated email will be sent on {props.nextSendOn.toLocaleDateString()} at {props.nextSendOn.toLocaleTimeString()}
        </p>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th className={"col-md-1"}/>
                    <th className={"col-md-3"}>Email</th>
                    <th className={"col-md-8"}>Status</th>
                </tr>
            </thead>
            <tbody>
                {props.records.map(resultRow)}
            </tbody>
        </Table>
    </div>
}

function ManualSend(props: { sendEmail: CallableFunction, error: string }): JSX.Element {
    return <Container fluid style={{padding: "10px"}}>
        <p style={{fontSize: "1.33rem", marginBottom: "10px"}}>Send Now</p>
        <Row style={{height: "100%"}}>
            <Container fluid className={"col-md-2"}>
                Send to Email(s):
            </Container>
            <Container fluid className={"col-md-10"}>
                <Row style={{paddingBottom: "10px", height: "100%"}}>
                    <textarea
                        id={"monthly_metrics_recipients"}
                        className={"border"}
                        style={{resize: "none", flexGrow: 1}}/>
                </Row>
                <Row style={{justifyContent:"space-between"}}>
                    <Col style={{display:"flex", padding:'unset'}}>
                        <p style={{fontSize: "0.8rem", color: "#e64943", margin: 0}}>{props.error}</p>
                    </Col>
                    <Col className={"col-sm-auto"} style={{display:"flex", padding:'unset', justifyContent: "flex-end"}}>
                        <Button className={"eevi_edit_button"}
                                size={"sm"}
                                outline={true}
                                color={"secondary"}
                                onClick={
                                    () => {
                                        let input: string = (document.getElementById("monthly_metrics_recipients") as HTMLInputElement).value
                                        let recipients: string[] = input.split(";").map((e)=>{return e.trim()})
                                        props.sendEmail(recipients)
                                    }
                                }
                                style={{maxHeight: '3.5vh'}}
                        >
                            Send
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Row>
    </Container>
}

function ProgressModal(props:{records: EmailStatusRecord[], visible: boolean, onClose: CallableFunction}): JSX.Element{
    return <Modal centered className={"MetricsReportPage"} isOpen={props.visible}>
        <ModalHeader>Sending...</ModalHeader>
        <ModalBody>
            <Table borderless style={{margin:"0px"}}>
                <tbody>
                    {
                        props.records.map(
                            (r)=>{
                                return <tr key={r.id}>
                                    <td className={"col-md-1"}>{r.status_icon}</td>
                                    <td className={"col-md-8"}>{r.email}</td>
                                    <td className={"col-md-3"}>{r.emailStatus ? toCapitalised(r.emailStatus.valueOf()) : ""}</td>
                                </tr>
                            }
                        )
                    }
                </tbody>
            </Table>
        </ModalBody>
        <ModalFooter>
            <Button color={"primary"} onClick={() => props.onClose()}>Close</Button>
        </ModalFooter>
    </Modal>
}

export default withRouter(MetricsReportForm);