import {React} from "./eevi_react_exports";

interface HelpLinkProps {
    link: string;
    content?: React.ReactNode | string;
    fontIconClass?: string;
}

export function EeviHelpLink(props: HelpLinkProps) {
    return <div className="ml-md-3 ml-sm-2 mr-md-3 mr-sm-2">
        <a href={props.link}
           target="_blank"
           className="btn eevi_light_grey_color p-0 eevi_dropdown btn btn-secondary eevi_no_focus">
            <i className={props.fontIconClass || "far fa-question-circle"}/>
            {props.content || ""}
        </a>
    </div>;
}

