import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '../../../common/web_common/styles/eevi.css';
import '../src/styles/dashboard.css';


import {HomeForm} from "./forms/home_form";
import {EeviAdminOrganisationsForm} from "../../../common/web_common/forms/eevi_admin_organisations_form";
import {EeviAdminPermissionsForm} from "../../../common/web_common/forms/eevi_admin_permissions_form";
import {TestForm} from "./forms/test_form";
import {AlarmDetailsForm} from "./forms/alarm_details_form";
import {DeviceSnapshotsForm} from "./forms/device_snapshots_form";
import {InstallationSnapshotForm} from "./forms/installation_snapshots_form";
import {MobileNetworkEventsForm} from "./forms/mobile_network_events_form";
import {EeviLogoutForm} from "../../../common/web_common/forms/eevi_logout_form";
import {React, ReactDOM, Route, Router} from "../../../common/web_common/components/eevi_react_exports";
import MetricsReportForm from "./forms/metrics_report_form";


const App = () => {
    return <Router>
        <Route
            exact path='/(|index.html|index)'
            component={HomeForm}/>
        <Route
            exact path='/groups/:groupLevel/alarm-details/:date/:intervalType/:mode'
            component={AlarmDetailsForm}/>
        <Route
            exact path='/groups/:groupLevel/device-snapshots/:date/:intervalType'
            component={DeviceSnapshotsForm}/>
        <Route
            exact path='/groups/:groupLevel/installation-snapshots/:date/:intervalType'
            component={InstallationSnapshotForm}/>
        <Route
            exact path='/groups/:groupLevel/installation-snapshots/:date/:intervalType/:mode'
             component={InstallationSnapshotForm}/>
        <Route
            exact path='/groups/:groupLevel/mobile-network-events/:date/:intervalType/:mode'
            component={MobileNetworkEventsForm}/>
        <Route exact path='/organisations'
               component={EeviAdminOrganisationsForm}/>
        <Route
            exact path='/accounts'
            component={EeviAdminPermissionsForm}/>
        <Route
            exact path='/logout'
            component={EeviLogoutForm}/>
        <Route
            exact path='/test'
            component={TestForm}/>
        <Route exact path='/metrics_report' component={MetricsReportForm}/>
    </Router>
};

ReactDOM.render(<App/>, document.getElementById('app'));