import {React} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviColumnDefinition, EeviTable} from "../../../../common/web_common/components/eevi_table";
import {
    defaultValue,
    localDateTimeString, titleCase,
    use
} from "../../../../common/web_common/components/eevi_transform";
import {EeviEventHandler} from "../../../../common/web_common/components/eevi_event_handler";
import {isNil} from "../../../../common/web_common/components/eevi_util";
import {renderAllDetails} from "./render_device_icon";
import {getHeaderText} from "./dashboard_util";

export class AlarmDetails  {
    eventId: string = "";
    eventIsoTime: string = "";
    eventType: string = "";
    details: string = "";
    organisationLevel: string = "";
    organisationTitle: string = "";
    responseSeconds?: number = 0;
    callDurationSeconds?: number = 0;
    acknowledged: boolean = false;
    commonArea: boolean = false;
    devices: string[] = [];
}

export class ComputedAlarmDetails extends AlarmDetails {

    public constructor(initData: Partial<AlarmDetails>) {
        super();
        Object.assign(this, initData);
    }

    get allDetails(): React.ReactNode {
        return renderAllDetails(this.details, this.devices);
    }

    get formattedResponse(): string {
        if (this.responseSeconds) {
            return this.responseSeconds.toString();
        } else if (this.devices.length > 0 && this.devices[0].includes('watch')) {
            return 'Response not monitored';
        } else {
            return 'No response';
        }
    }
}


function passGosStyle(responseSeconds?: number) {
    if (responseSeconds) {
        return responseSeconds <= 120 ? 'icon-tick' : 'icon-error';
    } else {
        return '';
    }
}


function passGosValue(responseSeconds?: number) {
    if (responseSeconds) {
        return '';
    } else {
        return '-';
    }
}

/**
 * Note: dataKey must be a property of ComputedAlarmDetails or you will get a compile error!!!
 */
const columnDefinitions: Array<EeviColumnDefinition<ComputedAlarmDetails>> = [
    {
        columnHeaderTitle: "Date",
        dataKey: "eventIsoTime",
        formatter: localDateTimeString
    },
    {
        columnHeaderTitle: "Resident",
        dataKey: "allDetails",
        styles: [use('keep-lines'), use('text-justify')]
    },
    {
        columnHeaderTitle: "Response",
        dataKey: "formattedResponse",
        styles: [use('center')]
    },
    {
        columnHeaderTitle: "Call Time",
        dataKey: "callDurationSeconds",
        formatter: defaultValue('-'),
        styles: [use('center')]
    },
    {
        columnHeaderTitle: "Group",
        dataKey: "organisationTitle",
        formatter: defaultValue('-'),
        styles: [defaultValue('center')]
    },
    {
        columnHeaderTitle: "GOS",
        dataKey: "responseSeconds",
        formatter: passGosValue,
        styles: [use('center'), passGosStyle]
    }


];

const primaryKey: keyof AlarmDetails = "eventId";

interface TableProps {
    alarms: Array<AlarmDetails>;
    onSelect?: EeviEventHandler<AlarmDetails>;
    mode: string;
    scrollDownForMore: boolean;
}

function getFilter(props: TableProps) {
    if (props.mode === 'OPEN') {
        return (a: AlarmDetails) => !a.acknowledged && isNil(a.responseSeconds)
    } else if (props.mode === 'RESPONSE' || props.mode === 'GOS') {
        return (a: AlarmDetails) => !isNil(a.responseSeconds) && !a.commonArea;
    }
    else {
        return (a: AlarmDetails) => true;
    }
}

function getTitle(props: TableProps): string {
    if (props.mode === 'OPEN') {
        return 'Open Alarms Awaiting Response';
    } else if (props.mode === 'RESPONSE') {
        return 'Alarm Responses';
    } else if (props.mode === 'GOS') {
        return 'Alarms Used in GOS Metrics';
    } else if (props.mode === 'ALL') {
        return 'All Alarm Details';
    }
    return titleCase(props.mode);
}


export function AlarmDetailsTable(props: TableProps) {
    let filter = getFilter(props);
    const items = props.alarms.filter(filter).map(a => new ComputedAlarmDetails(a));
    if (items.length == 0) {
        return <h5 className="mx-auto p-5">No alarms</h5>;
    }
    // TODO: css / standardize this.
    return <div className="p-3 mx-auto mt-3" style={{maxWidth: 1400}}>
        <h5>{getTitle(props)}</h5>
        <h5 className="font-weight-normal">{getHeaderText(items.length, props.scrollDownForMore)}</h5>
        <EeviTable
            columnDefinitions={columnDefinitions}
            primaryKey={primaryKey}
            data={items}
            onSelect={props.onSelect}/>
    </div>;
}