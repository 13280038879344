import {React} from "../../../../common/web_common/components/eevi_react_exports";
import {apiV1} from "../../../../common/web_common/containers/eevi_std_container";
import {DashboardLookup} from "../components/organisation";
import {DetailsForm, DetailsProps, DetailsState} from "./details_form_base";
import {MobileNetworkEvent, MobileNetworkEventsTable} from "../components/mobile_network_events_table";

const help_url = "http://assets.eevi.life/dashboard/mobile_network_events_help_v1_0.html";

interface MobileNetworkEventsState extends DetailsState {
    networkEvents: MobileNetworkEvent[];
    totalTimeSeconds: number;
}


/**
 * This form is usually called from a dashboard drill-down.
 */
export class MobileNetworkEventsForm extends DetailsForm<MobileNetworkEventsState> {

    constructor(props: DetailsProps) {
        super(props, "Mobile Network Outages", true);

        this.state = {
            ...this.initialState(),
            networkEvents: [],
            totalTimeSeconds: 0
        };

    }

    /**
     * Let base class know where our help is
     */
    protected getHelpUrl(): string {
        return help_url;
    }

    /**
     * Read any parameters from the url and kick off a call to the back end.
     * Note that the EeviAp instance is bound to the form so it can automatically update
     * the form state with the retrieved data.
     */
    protected onFormDidMount() {
        const params = this.getUrlParameters();
        let url =
            `${apiV1}/mobile-network-events?group=${params.group}` +
            `&endDate=${encodeURIComponent(params.endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(params.startDate.toISOString())}`;
        this.api.get(url);
    }



    /**
     * Event handler when the user chooses a different organisation / level from the filter menu.
     */
    protected onChangeOrganisation(dl: DashboardLookup) {
        this.changeOrganisation(dl);

        const redirect =
            `/groups/${dl.organisationLevel}/mobile-network-events` +
            `/${this.state.startIsoTime}/${this.state.endIsoTime}//${this.getFormMode()}`;
        // TODO: there's a weird issue with the new react router stuff
        // we are only getting the url change. That will suffice for now since we already had the code
        // to update this form.
        this.props.history.push(redirect);

        this.api.get(
            `${apiV1}/mobile-network-events` +
            `?group=${dl.organisationLevel}` +
            `&endDate=${encodeURIComponent(this.state.endIsoTime!)}` +
            `&startDate=${encodeURIComponent(this.state.startIsoTime!)}`
        );

    }

    /**
     * Event handler for when the user changes the start or end date.
     */
    protected onChangeDateRange(startDate: Date, endDate: Date) {
        const redirect =
            `/groups/${this.state.organisationLevel}/mobile-network-events` +
            `/${startDate.toISOString()}/${endDate.toISOString()}/${this.getFormMode()}`;
        // TODO: there's a weird issue with the new react router stuff
        // we are only getting the url change. That will suffice for now since we already had the code
        // to update this form.
        this.props.history.push(redirect);
        this.setState({loading: true });
        this.api.get(
            `${apiV1}/mobile-network-events` +
            `?group=${this.state.organisationLevel}` +
            `&endDate=${encodeURIComponent(endDate.toISOString())}` +
            `&startDate=${encodeURIComponent(startDate.toISOString())}`
        );

    }

    /**
     * Called by the base class render to display the content beneath the menus / navigation bar area.
     */
    renderForm(): React.ReactNode {
        return <MobileNetworkEventsTable
            networkEvents={this.state.networkEvents}
            status={this.getFormMode()}
            totalTimeSeconds={this.state.totalTimeSeconds}/>;
    }

}

