import {EeviForm, EeviFormProperties, EeviFormState} from "../../../../common/web_common/forms/eevi_form";
import {React, Redirect, Link} from "../../../../common/web_common/components/eevi_react_exports";



interface TestState extends EeviFormState {

}


export class TestForm extends EeviForm<EeviFormProperties, TestState> {

    constructor(props: EeviFormProperties) {
        super(props, "Test");
        this.state = {
            ...this.initialState(),
            loading: false
        };
    }


    renderForm(): React.ReactNode {
        return <>
            <Redirect to={"/"}/>
            <Link to={"/"}>Home</Link>
        </>;
    }

}
