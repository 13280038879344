import {React} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviColumnDefinition, EeviTable} from "../../../../common/web_common/components/eevi_table";
import {
    defaultValue, localDateTimeString, titleCase, use
} from "../../../../common/web_common/components/eevi_transform";
import {EeviEventHandler} from "../../../../common/web_common/components/eevi_event_handler";
import {renderAllDetails} from "./render_device_icon";
import {getHeaderText} from "./dashboard_util";

export class InstallationSnapshot  {
    eventId: string = "";
    organisationLevel: string = "";
    organisationTitle: string = "";
    details: string = "";
    snapshotDay?: number;
    installationTraits: string[] = [];
    installationFaults: string[] = [];
    devices: string[] = [];
}



/**
 * Adds some calculated columns to what comes across the wire
 */
class ComputedInstallationSnapshot extends InstallationSnapshot {
    status: string = "";
    faults: string = "";
    allDetails: any = "";

    constructor(initData: Partial<InstallationSnapshot>) {
        super();
        Object.assign(this, initData);
        const traits = this.installationTraits;
        const faults = this.installationFaults;
        faults.sort();
        this.status = 'not installed';
        if (traits.includes('active') && faults.length === 0) {
            this.status = 'active online';
        } else if (traits.includes('active')) {
            this.status = 'active fault'
        } else if (faults.length > 0) {
            this.status = 'fault'
        } else if (traits.includes('installed')) {
            this.status = 'installed';
        }
        this.faults = faults.join('\n');
        this.allDetails = renderAllDetails(this.details, this.devices)
    }

}


/**
 * Note: dataKey must be a property of InstallationSnapshots of you will get a compile error!!!
 */
const columnDefinitions: Array<EeviColumnDefinition<ComputedInstallationSnapshot>> = [
    {
        columnHeaderTitle: "Date",
        dataKey: "snapshotDay",
        formatter: localDateTimeString
    },
    {
        columnHeaderTitle: "Resident",
        dataKey: "allDetails",
        styles: [use('keep-lines'), use('text-justify')]
    },
    {
        columnHeaderTitle: "Status",
        dataKey: "status",
        styles: [use('center')]
    },
    {
        columnHeaderTitle: "Faults",
        dataKey: "faults",
        formatter: defaultValue('ok'),
        styles: [use('keep-lines'), use('text-justify')]
    },
    {
        columnHeaderTitle: "Group",
        dataKey: "organisationTitle",
        formatter: defaultValue('-'),
        styles: [defaultValue('center')]
    }

];

const primaryKey: keyof InstallationSnapshot = "eventId";

interface TableProps {
    snapshots: Array<InstallationSnapshot>;
    onSelect?: EeviEventHandler<InstallationSnapshot>;
    status: string;
    scrollDownForMore: boolean;
}

function getFilter(props: TableProps, status: string) {
    let filter = (a: ComputedInstallationSnapshot) => true;
    if (status !== 'all') {
        filter = (a: ComputedInstallationSnapshot) => a.status.includes(status);
    }
    return filter;
}

function getTitle(status: string) : string {
    return titleCase(status) + " Installations";
}


export function InstallationSnapshotTable(props: TableProps) {
    const status = props.status.replace('-', ' ').toLowerCase();
    let filter = getFilter(props, status);
    const compute = (i: InstallationSnapshot) => new ComputedInstallationSnapshot(i);
    const items = props.snapshots.map(compute).filter(filter);
    if (items.length == 0) {
        return <h5 className="mx-auto p-5">No installations</h5>;
    }
    // TODO: css / standardize this.
    return <div className="p-3 mx-auto mt-3" style={{maxWidth: 1400}}>
        <h5>{getTitle(status)}</h5>
        <h5 className="font-weight-normal">{getHeaderText(items.length,props.scrollDownForMore )}</h5>
        <EeviTable
            columnDefinitions={columnDefinitions}
            primaryKey={primaryKey}
            data={items}
            onSelect={props.onSelect}/>
    </div>;
}