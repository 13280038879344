import {React} from "../../../../common/web_common/components/eevi_react_exports";
import {EeviColumnDefinition, EeviTable} from "../../../../common/web_common/components/eevi_table";
import {
    defaultValue,
    localDateTimeString,
    use
} from "../../../../common/web_common/components/eevi_transform";
import {EeviEventHandler} from "../../../../common/web_common/components/eevi_event_handler";
import {getHeaderText} from "./dashboard_util";

export interface DeviceSnapshot {
    eventId: string;
    organisationLevel: string;
    organisationTitle: string;
    details: string;
    snapshotDay: number;
    lastChecked: number;
    lastSeen: number;
    batteryPercentage?: number;
}

type ComputedDeviceSnapshot = DeviceSnapshot & { online: boolean };

function snapShotWithCalcs(sn: DeviceSnapshot): ComputedDeviceSnapshot {
    return {
        ...sn,
        online: sn.lastSeen && sn.lastChecked ? (sn.lastChecked - sn.lastSeen) <= 3600 : false
    };
}

const tickIfOnline = (online?: number) => online ? 'icon-tick' : 'icon-error';

/**
 * Note: dataKey must be a property of DeviceSnapshots of you will get a compile error!!!
 */
const columnDefinitions: Array<EeviColumnDefinition<ComputedDeviceSnapshot>> = [
    {
        columnHeaderTitle: "Resident",
        dataKey: "details",
        styles: [use('keep-lines'), use('text-justify')]
    },
    {
        columnHeaderTitle: "Last Checked",
        dataKey: "lastChecked",
        formatter: localDateTimeString
    },
    {
        columnHeaderTitle: "Last Seen",
        dataKey: "lastSeen",
        formatter: localDateTimeString
    },
    {
        columnHeaderTitle: "Battery %",
        dataKey: "batteryPercentage",
        formatter: defaultValue('-'),
        styles: [use('center')]
    },
    {
        columnHeaderTitle: "Online",
        dataKey: "online",
        formatter: use(''),
        styles: [use('center'), tickIfOnline]
    },
    {
        columnHeaderTitle: "Group",
        dataKey: "organisationTitle",
        formatter: defaultValue('-'),
        styles: [defaultValue('center')]
    }

];

const primaryKey: keyof DeviceSnapshot = "eventId";

export function DeviceSnapshotsTable(
    props: {
        snapshots: Array<DeviceSnapshot>;
        onSelect?: EeviEventHandler<DeviceSnapshot>;
        scrollDownForMore: boolean;
    }) {
    if (props.snapshots.length == 0) {
        return <p className="mx-auto p-5">No devices</p>;
    }
    // TODO: css / standardize this.
    return <div className="p-3 mx-auto mt-3" style={{maxWidth: 1400}}>
        <h5 className="font-weight-normal">{getHeaderText(props.snapshots.length, props.scrollDownForMore)}</h5>
        <EeviTable
            columnDefinitions={columnDefinitions}
            primaryKey={primaryKey}
            data={props.snapshots.map(snapShotWithCalcs)}
            onSelect={props.onSelect}/>
    </div>;
}